<!--
 * @Author: your name
 * @Date: 2020-11-27 08:46:03
 * @LastEditTime: 2020-12-28 17:46:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nethallofficialaccounts\src\views\Business\components\BusinessStep.vue
-->
<template>
  <div class="business-step">
    <div class="business-step-head">
      <span>办理记录</span>
    </div>
    <div class="business-step-steps">
      <!-- inactive-color="#4A90E2" :active="1" -->
      <!-- inactive-color="#7ED321" -->
      <van-steps direction="vertical" :active="active" active-icon="more" inactive-icon="checked" > 
        <van-step v-for="(item, index) in stepData" :key="index">
          <div class="bss-title" v-if="item.statusName !== undefined">{{ item.statusName }}</div>
          <div class="bss-describe" v-if="item.statusCode === 'nh_00_01_st'">
            {{ item.processingDays !== undefined ?  `剩余操作时间${item.processingDays}天` : '' }}
          </div>
          <div class="bss-describe" v-else>
            {{ item.processingDays ? `预计${item.processingDays}个工作日完成`: ''}}
          </div>
          <div class="bss-describe" v-if="item.createdTime">
            {{ item.createdTime }}
          </div>
          <div class="bss-describe" v-if="item.remark !== undefined">{{ item.remark }}</div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { selectBusHandlingProgess } from '@/api/business';
export default {
  name: 'BusinessStep',
  //定义变量
  data() {
    return {
      busHandlingId: this.$route.query.busHandlingId,
      stepData: null,
      createdTime: null,
      statusFlag: true, // true 显示多少个工作日, false显示时间。
      active: 0,
    };
  },
  computed: {
    ...mapState('user', {
      businessNews: (state) => state.businessNews
    })
  },
  mounted() {
    this.into();
  },
  methods: {
    into() {
      document.title =
        this.businessNews.moduleName !== undefined ? this.businessNews.moduleName : '步骤信息';
      this.selectBusHandlingProgess();
    },
    async selectBusHandlingProgess() {
      if (this.businessNews && this.businessNews.busHandlingId) {
        const { status, resultData } = await selectBusHandlingProgess(
          this.businessNews.busHandlingId
        );
        if (status === 'complete') {
          this.stepData = resultData;
          this.createdTime = resultData[0].createdTime;
          this.active = resultData && resultData.length - 1
          console.log('PP---', this.active)
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.business-step {
  padding: 10px 16px;
  border-radius: 8px;

  .business-step-head {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 10px;
  }

  .business-step-steps {
    border-radius: 8px;
    overflow: hidden;
    /deep/.van-icon-checked {
      color: #7ED321;
      // color: #4380e4;
    }
    /deep/.van-icon-more {
      color: #4380e4;
    }
    /deep/.van-step__line {
      // background-color:#969799 !important;
      background-color:#cccccc !important;
    }
    .bss-title {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }

    .bss-describe {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
